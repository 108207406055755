<template>
  <table class="table table-hover table-sm bg-white table-striped rounded ">
    <thead>
      <tr>
        <th
          class="text-center"
          style="vertical-align: middle; min-width: 100px; font-size: large; "
        >
          Operación
        </th>
        <th
          class="text-center"
          style="vertical-align: middle; min-width: 90px; font-size: large;"
        >
          Múltiple
        </th>
        <th
          v-for="(fecha, index) in $parent.total_dias"
          :key="index"
          class="text-center"
        >
          <div style="font-size: 11px;" class="bg-navy rounded-top">
            DD/MM/YY
          </div>
          <div class="bg-white rounded-bottom border border-info">
            {{ fecha }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-bold mr-2">
          <div class="mb-3 mt-2">Crudo</div>
          <div class="mb-3">Planta externa</div>
          <div class="mb-3">Regalías</div>
          <div>Invetarios</div>
        </td>
        <td class="text-center">
          <button
            v-if="loanding.crudo"
            class="btn btn-success shadow mt-2"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
          <div v-else class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focusMultiple('crudo')"
              @change="asiganacionmultiple"
              @keydown="validarNumeros"
              v-model="crudo"
              :disabled="$parent.bloqueado"
              type="text"
              :class="
                tipoInput == 'crudo' && multiple && $parent.check_active
                  ? 'bg-white shadow border border-success shadow-lg'
                  : 'alert-default-success border border-success'
              "
              class="form-control form-control-sm col-md-6
                              text-center text-bold p-0 shadow-sm"
            />
          </div>

          <button
            v-if="loanding.planta"
            class="btn btn-success shadow mt-2"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
          <div v-else class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focusMultiple('planta')"
              @change="asiganacionmultiple"
              @keydown="validarNumeros"
              v-model="planta"
              :disabled="$parent.bloqueado"
              type="text"
              :class="
                tipoInput == 'planta' && multiple && $parent.check_active
                  ? 'bg-white shadow border border-success shadow-lg'
                  : 'alert-default-success border border-success'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>

          <button
            v-if="loanding.regalias"
            class="btn btn-success shadow mt-2"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
          <div v-else class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focusMultiple('regalias')"
              @change="asiganacionmultiple"
              @keydown="validarNumeros"
              v-model="regalias"
              :disabled="$parent.bloqueado"
              type="text"
              :class="
                tipoInput == 'regalias' && multiple && $parent.check_active
                  ? 'bg-white shadow border border-success shadow-lg'
                  : 'alert-default-success border border-success'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>

          <button
            v-if="loanding.inventario"
            class="btn btn-success shadow mt-2"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
          <div v-else class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focusMultiple('inventario')"
              @change="asiganacionmultiple"
              @keydown="validarNumeros"
              v-model="inventario"
              :disabled="$parent.bloqueado"
              type="text"
              :class="
                tipoInput == 'inventario' && multiple && $parent.check_active
                  ? 'bg-white shadow border border-success'
                  : 'alert-default-success border border-success'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>
        </td>
        <td
          class="text-center"
          v-for="(detalle, index) in $parent.operacionDetalles.filter(
            (detalle) => {
              return detalle.producto_liquido_id == producto.id;
            }
          )"
          :key="index + 1"
        >
          <div class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focus(index + 1, producto.id, 'crudo')"
              v-model="detalle.crudo_ventas"
              @keydown="validarNumeros"
              :disabled="$parent.bloqueado"
              type="text"
              :class="
                indexFocus == index + 1 &&
                tipoInput == 'crudo' &&
                multiple == false &&
                $parent.check_active
                  ? 'bg-white shadow border border-warning'
                  : 'alert-default-warning border border-warning'
              "
              class="form-control form-control-sm col-md-6
                              text-center text-bold p-0 shadow-sm"
            />
          </div>
          <div class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focus(index + 1, producto.id, 'planta')"
              type="text"
              @keydown="validarNumeros"
              v-model="detalle.planta_externa"
              :disabled="$parent.bloqueado"
              :class="
                indexFocus == index + 1 &&
                tipoInput == 'planta' &&
                multiple == false &&
                $parent.check_active
                  ? 'bg-white shadow border border-warning'
                  : 'alert-default-warning border border-warning'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>
          <div class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focus(index + 1, producto.id, 'regalias')"
              type="text"
              @keydown="validarNumeros"
              v-model="detalle.regalias"
              :disabled="$parent.bloqueado"
              :class="
                indexFocus == index + 1 &&
                tipoInput == 'regalias' &&
                multiple == false &&
                $parent.check_active
                  ? 'bg-white shadow border border-warning'
                  : 'alert-default-warning border border-warning'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>
          <div class="d-flex justify-content-center">
            <input
              style="min-width: 40px;"
              @focus="focus(index + 1, producto.id, 'inventario')"
              type="text"
              @keydown="validarNumeros"
              v-model="detalle.inventario"
              :disabled="$parent.bloqueado"
              :class="
                indexFocus == index + 1 &&
                tipoInput == 'inventario' &&
                multiple == false &&
                $parent.check_active
                  ? 'bg-white shadow border border-warning'
                  : 'alert-default-warning border border-warning'
              "
              class="form-control form-control-sm col-md-6 text-center mt-2 text-bold p-0 shadow-sm"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
//import axios from "axios";
//import vSelect from "vue-select";

export default {
  name: "ProyeccionHidrocarburosDetalleForm",
  components: {},
  data() {
    return {
      indexFocus: null,
      multiple: false,
      productoFocus: null,
      tipoInput: null,
      producto: this.productoProp ? this.productoProp : {},
      detalle: {},
      loanding: {
        crudo: false,
        planta: false,
        regalias: false,
        inventario: false,
      },
      crudo: null,
      planta: null,
      regalias: null,
      inventario: null,
    };
  },

  props: {
    productoProp: Object,
  },

  methods: {
    focus(indexFocus, productoFocus, tipoInput) {
      this.multiple = false;
      this.indexFocus = null;
      this.productoFocus = null;
      this.tipoInput = null;
      this.indexFocus = indexFocus ? indexFocus : null;
      this.productoFocus = productoFocus ? productoFocus : null;
      this.tipoInput = tipoInput ? tipoInput : null;
    },

    focusMultiple(tipoInput) {
      this.multiple = true;
      this.tipoInput = tipoInput ? tipoInput : null;
    },

    asiganacionmultiple() {
      switch (this.tipoInput) {
        case "crudo":
          this.loanding.crudo = false;
          this.loanding.crudo = true;
          this.$parent.operacionDetalles.filter((detalle, index) => {
            if (detalle.producto_liquido_id == this.producto.id) {
              this.$parent.operacionDetalles[index].crudo_ventas = this.crudo;
            }
          });
          this.crudo = null;
          this.loanding.crudo = false;
          break;
        case "planta":
          this.loanding.planta = false;

          this.loanding.planta = true;
          this.$parent.operacionDetalles.filter((detalle, index) => {
            if (detalle.producto_liquido_id == this.producto.id) {
              this.$parent.operacionDetalles[
                index
              ].planta_externa = this.planta;
            }
          });
          this.planta = null;
          this.loanding.planta = false;
          break;
        case "regalias":
          this.loanding.regalias = true;

          this.loanding.regalias = true;
          this.$parent.operacionDetalles.filter((detalle, index) => {
            if (detalle.producto_liquido_id == this.producto.id) {
              this.$parent.operacionDetalles[index].regalias = this.regalias;
            }
          });

          this.regalias = null;
          this.loanding.regalias = false;
          break;
        case "inventario":
          this.loanding.inventario = true;

          this.loanding.inventario = true;
          this.$parent.operacionDetalles.filter((detalle, index) => {
            if (detalle.producto_liquido_id == this.producto.id) {
              this.$parent.operacionDetalles[
                index
              ].inventario = this.inventario;
            }
          });
          this.inventario = null;
          this.loanding.inventario = false;
          break;
        default:
          break;
      }
    },

    validarNumeros(event) {
      if (
        // Números del teclado principal
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        // Números del teclado numérico
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        // Retroceso
        event.keyCode == 8 ||
        // Suprimir
        event.keyCode == 46 ||
        // Punto decimal
        event.keyCode == 190 ||
        event.keyCode == 110 ||
        // Tab
        event.keyCode == 9 ||
        // Flecha izquierda y derecha
        event.keyCode == 37 ||
        event.keyCode == 39 ||
        // Teclas de modificación (Alt, Ctrl, Shift y Alt Gr)
        event.altKey ||
        event.ctrlKey ||
        event.shiftKey ||
        event.altGraphKey ||
        // Tecla Enter (teclado principal y numérico)
        event.keyCode == 13 ||
        event.keyCode == 108
      ) {
        // Permitir la tecla
        return true;
      } else {
        this.$swal({
          icon: "error",
          title: "Debe ingresar solo números en el campo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        // Prevenir la acción predeterminada de la tecla
        event.preventDefault();
        return false;
      }
    },
  },
};
</script>

<style>
.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #051650;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
